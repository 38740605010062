// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconAlertCircle = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4.5C16.1362 4.5 19.5 7.86375 19.5 12C19.5 16.1362 16.1362 19.5 12 19.5C7.86375 19.5 4.5 16.1362 4.5 12C4.5 7.86375 7.86375 4.5 12 4.5ZM12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM12 17.9287C11.1938 17.9287 10.5413 17.2762 10.5413 16.47C10.5413 15.6637 11.1938 15.0112 12 15.0112C12.8063 15.0112 13.4588 15.6637 13.4588 16.47C13.4588 17.2762 12.8063 17.9287 12 17.9287ZM13.41 12.0901C13.7737 10.6801 13.9687 9.19883 13.9837 7.70633H13.9912C13.9987 7.00883 13.6425 6.40508 13.1437 6.25883C12.39 6.03758 11.6137 6.03758 10.8637 6.25883C10.365 6.40508 10.0087 7.00883 10.0162 7.70633C10.0312 9.19883 10.2262 10.6801 10.59 12.0901L10.7287 12.6301C10.8525 13.1138 11.1562 13.4738 11.5275 13.5751C11.8387 13.6613 12.1612 13.6613 12.4725 13.5751C12.84 13.4738 13.1475 13.1176 13.2712 12.6301L13.41 12.0901Z" /></svg>;
};