// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPoints = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.625C6.82125 2.625 2.625 6.82125 2.625 12C2.625 17.1788 6.82125 21.375 12 21.375C17.1788 21.375 21.375 17.1788 21.375 12C21.375 6.82125 17.1788 2.625 12 2.625ZM17.3025 10.9237L15.7537 12.4313C15.375 12.7988 15.2025 13.3312 15.2925 13.8525L15.6562 15.9825C15.7537 16.545 15.1613 16.9725 14.6588 16.7062L12.7463 15.7013C12.2775 15.4537 11.7188 15.4537 11.25 15.7013L9.3375 16.7062C8.83125 16.9725 8.2425 16.5413 8.34 15.9825L8.70375 13.8525C8.79375 13.3312 8.62125 12.7988 8.2425 12.4313L6.69375 10.9237C6.285 10.5262 6.51 9.8325 7.07625 9.75L9.21375 9.43875C9.73875 9.36375 10.1887 9.03375 10.425 8.56125L11.3813 6.6225C11.6325 6.1125 12.3637 6.1125 12.615 6.6225L13.5712 8.56125C13.8037 9.03375 14.2575 9.36375 14.7825 9.43875L16.92 9.75C17.4825 9.8325 17.7112 10.5262 17.3025 10.9237Z" /></svg>;
};