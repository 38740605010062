// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconSearch = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19.4412 16.4204L15.6874 13.6754C15.3162 13.4016 15.2299 12.8954 15.4662 12.4979C16.8499 10.1954 16.6961 7.17661 14.8736 5.00161C13.5911 3.47536 11.7574 2.68787 9.90864 2.68787C8.23239 2.68787 6.54863 3.33662 5.25488 4.66412C3.13238 6.84662 2.83989 10.3079 4.56489 12.8129C5.84364 14.6729 7.8724 15.6366 9.91615 15.6366C10.7037 15.6366 11.4949 15.4904 12.2449 15.1979C12.6761 15.0291 13.1599 15.2054 13.3661 15.6179L15.4174 19.7879C15.6049 20.1629 15.8862 20.5116 16.2462 20.7741C19.2087 22.7954 22.2537 18.6291 19.4412 16.4204ZM6.6349 11.9166C5.11615 10.1091 5.35241 7.40163 7.16366 5.88663C7.93241 5.24163 8.91115 4.88536 9.9124 4.88536C11.1836 4.88536 12.3799 5.44412 13.1936 6.41537C14.7124 8.22287 14.4761 10.9304 12.6649 12.4454C11.8961 13.0904 10.9174 13.4466 9.91615 13.4466C8.64865 13.4466 7.4524 12.8879 6.6349 11.9166Z" /></svg>;
};