// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconArrowUpRight = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.402 18.3175L15.4607 8.71378C15.562 8.59003 15.7607 8.64629 15.7907 8.80004L16.4095 12.2725C16.4957 12.7563 16.9232 13.1313 17.4107 13.0938C17.9732 13.0488 18.3595 12.535 18.2657 11.995L17.3282 6.71504C17.1857 5.90879 16.4845 5.32378 15.6707 5.32378L10.3082 5.31628C10.0195 5.31628 9.76073 5.44754 9.58823 5.65004C9.45698 5.80379 9.37824 6.00254 9.37074 6.22004C9.35199 6.75254 9.83198 7.19128 10.3682 7.19128L13.627 7.19504C14.047 7.19504 14.1332 7.38254 14.032 7.50254L5.93949 17.1475C5.58699 17.5675 5.66574 18.2088 6.13074 18.5275C6.52824 18.8088 7.08325 18.6925 7.402 18.3175Z" /></svg>;
};