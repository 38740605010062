// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconTriangleRight = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.03825 5.39167C8.8095 5.57542 8.7045 5.85291 8.70825 6.13416H8.70449L8.68575 17.7967C8.682 17.8267 8.68575 17.8567 8.68575 17.8867V17.9167C8.69325 18.1979 8.82449 18.4717 9.07949 18.6517C9.48074 18.9367 10.0395 18.8317 10.362 18.4604L14.8095 13.3604C15.4845 12.5879 15.4845 11.4217 14.8095 10.6492L10.3245 5.50792C10.1407 5.29417 9.87824 5.18542 9.6195 5.18542C9.41325 5.18917 9.207 5.25292 9.03825 5.39167Z" /></svg>;
};