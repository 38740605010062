// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMoreHorizontal = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.4826 12C14.4826 13.3762 13.3651 14.4937 11.9889 14.4937C10.6126 14.4937 9.49512 13.3762 9.49512 12C9.49512 10.6237 10.6126 9.50623 11.9889 9.50623C13.3651 9.50623 14.4826 10.6237 14.4826 12ZM19.8864 9.50623C18.5101 9.50623 17.3926 10.6237 17.3926 12C17.3926 13.3762 18.5101 14.4937 19.8864 14.4937C21.2626 14.4937 22.3801 13.3762 22.3801 12C22.3801 10.6237 21.2626 9.50623 19.8864 9.50623ZM4.11387 9.50623C2.73762 9.50623 1.62012 10.6237 1.62012 12C1.62012 13.3762 2.73762 14.4937 4.11387 14.4937C5.49012 14.4937 6.60762 13.3762 6.60762 12C6.60762 10.6237 5.49012 9.50623 4.11387 9.50623Z" /></svg>;
};