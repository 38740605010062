// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconAlertOffFilled = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 20.6231C10.7587 20.6231 9.75 19.6143 9.75 18.3731C9.75 18.1668 9.91875 17.9981 10.125 17.9981H13.875C14.0813 17.9981 14.25 18.1668 14.25 18.3731C14.25 19.6143 13.2413 20.6231 12 20.6231ZM6.7575 8.50683C6.7575 8.54433 6.75 8.58183 6.75 8.62308V10.2993C6.75 11.6493 6.33375 12.9656 5.56125 14.0681L4.33875 15.8118C4.2 16.0106 4.125 16.2506 4.125 16.4943C4.125 16.7006 4.29375 16.8693 4.5 16.8693H17.4712L6.7575 8.50307V8.50683ZM21.33 16.2281L18.3113 13.8731C17.6213 12.8118 17.25 11.5706 17.25 10.3031V8.62683C17.25 5.72808 14.8988 3.37683 12 3.37683C10.2263 3.37683 8.66625 4.25808 7.71375 5.60433L5.69625 4.02933C5.2875 3.71058 4.69875 3.78182 4.38 4.19057C4.06125 4.59932 4.1325 5.18808 4.54125 5.50683L20.175 17.7093C20.3475 17.8443 20.55 17.9081 20.7525 17.9081C21.03 17.9081 21.3075 17.7843 21.4912 17.5481C21.81 17.1393 21.7387 16.5506 21.33 16.2318V16.2281Z" /></svg>;
};