// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconRefreshClockwiseAlt = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || "")
  };
  return <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.96046 3.92688L10.1392 4.83812C10.5854 4.91687 10.9117 5.30313 10.9155 5.75688L10.9342 11.0144C10.9342 11.5319 10.5179 11.9519 10.0004 11.9556H9.99668C9.47918 11.9556 9.05918 11.5394 9.05918 11.0219L9.04797 8.04438C9.04797 7.80438 8.76672 7.67312 8.58297 7.82687C8.54922 7.85312 8.52293 7.87937 8.49668 7.89812C7.31168 8.93312 6.60295 10.3694 6.49795 11.9406C6.39295 13.5119 6.9067 15.0269 7.94545 16.2119C10.0867 18.6569 13.8142 18.9044 16.2592 16.7631C17.4442 15.7281 18.1529 14.2919 18.2579 12.7206C18.3629 11.1494 17.8492 9.63438 16.8104 8.44938C15.8879 7.39562 14.6167 6.70187 13.2292 6.49937C12.7154 6.42437 12.3629 5.94813 12.4379 5.43813C12.5129 4.92438 12.9892 4.56812 13.4992 4.64687C15.3292 4.91312 17.0055 5.82437 18.2205 7.21562C19.5855 8.77562 20.2642 10.7744 20.1254 12.8444C19.9867 14.9144 19.0529 16.8081 17.4929 18.1731C16.0229 19.4594 14.2005 20.0931 12.3817 20.0931C10.218 20.0931 8.06919 19.1969 6.53544 17.4456C5.17044 15.8856 4.49171 13.8869 4.63046 11.8169C4.76171 9.83688 5.6242 8.01813 7.0642 6.66813C7.0642 6.66813 7.06421 6.66812 7.06796 6.66437C7.24046 6.50312 7.15796 6.21437 6.92546 6.17312L4.63797 5.77187C4.12797 5.68187 3.7867 5.19438 3.8767 4.68813C3.96295 4.17813 4.44671 3.83688 4.96046 3.92688Z" /></svg>;
};